* {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  z-index: 1;
}

a {
  color: inherit;
  text-decoration: inherit;
}
