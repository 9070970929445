@import url("https://fonts.googleapis.com/css2?family=Baloo+Paaji+2&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  z-index: 1;
}
a {
  color: inherit;
  text-decoration: inherit;
}
* {
  font-family: "Baloo Paaji 2", sans-serif;
}
.title {
  margin: 16px 0;
  font-size: 36px;
  line-height: 1;
}
.subtitle {
  margin: 8px 0;
  font-size: 24px;
  line-height: 1;
  opacity: 0.7;
}
:root {
  --border-radius: 2px;
  --color-default: #e5e5e5;
  --color-primary: #8bc34a;
  --color-error: #f44336;
  --text-color-default: rgba(0, 0, 0, 0.9);
  --text-color-hint: rgba(0, 0, 0, 0.54);
  --text-color-primary: rgba(255, 255, 255, 0.9);
  --card-background: white;
  --default-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  --page-background: #ddd;
  --control-padding: 8px;
  --font-size: 12px;
  --default-transition: 0.1s ease-in-out all;
  --border-width: 2px;
  --button-default: rgba(0, 0, 0, 0.1);
  --icon-default: rgba(0, 0, 0, 0.54);
  --footer-color: rgba(0, 0, 0, 0.7);
}
@media screen and (max-device-width: 1024px) {
  :root {
    --control-padding: 12px;
  }
}
.body {
  background: var(--page-background);
  min-height: 100vh;
  height: 100%;
}
.button {
  padding: var(--control-padding);
  border-radius: var(--border-radius);
  border: none;
  background: var(--button-default);
  color: var(--text-color-default);
  cursor: pointer;
  font-size: var(--font-size);
  text-decoration: none;
  line-height: 1;
}
.icon-button {
  border: none;
  background: none;
  cursor: pointer;
  transition: var(--default-transition);
}
.icon-button:disabled {
  pointer-events: none;
}
.icon-button--active:not(:disabled) {
  color: var(--color-primary);
  opacity: 1;
}
.icon-button--hint,
.icon-button--inactive,
.icon-button:disabled {
  opacity: 0.54;
}
.icon-button--hint:hover:not(:disabled) {
  opacity: 1;
}
.button--primary {
  background-color: var(--color-primary);
  color: var(--text-color-primary);
}
.button--error {
  background-color: var(--color-error);
  color: var(--text-color-primary);
}
.button:disabled,
.button--disabled {
  cursor: not-allowed;
  opacity: 0.54;
  pointer-events: none;
}
.input,
.select {
  padding: calc(var(--control-padding) - var(--border-width));
  border-radius: var(--border-radius);
  background: var(--color-default);
  border: var(--border-width) solid transparent;
  transition: var(--default-transition);
}
.input:hover,
.select:hover {
  border-color: var(--color-primary);
}
.card {
  padding: 16px;
  background: var(--card-background);
  box-shadow: var(--default-shadow);
  border-radius: var(--border-radius);
  box-sizing: border-box;
}
.dialog {
  background: var(--page-background);
  top: 32px;
  bottom: 32px;
  left: 32px;
  right: 32px;
  position: absolute;
  border-radius: var(--border-radius);
}
.player-list__player-list,
.player-list__team-list {
  padding: 0;
  width: 100%;
}

.player-list__player-list-item {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.player-list__player-avatar {
  margin-right: 8px;
}

.player-list__player-name {
  flex: 1;
}

.player-list__player-list-item--self {
  color: var(--color-primary);
}

.player-list__team-header {
  display: flex;
  flex-direction: row;
}

.player-list__team-name {
  flex: 1;
}

.player-list__join-team-btn {
  border: none;
  text-transform: uppercase;
  background: none;
  cursor: pointer;
  transition: var(--default-transition);
  color: var(--text-color-hint);
}

.player-list__join-team-btn:hover {
  color: rgba(0, 0, 0, 1);
}

.player-avatar {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  transition: var(--default-transition), border-radius 0.1s ease-in;
}

.player-avatar:not(.player-avatar--passive):hover {
  background: white;
  transform: scale(5);
  border-radius: var(--border-radius);
  padding: 4px;
  box-shadow: var(--default-shadow);
  z-index: 2;
}

.lobby {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.lobby__card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  min-height: 500px;
  margin: 16px;
}

.lobby__settings-group {
  width: 100%;
  margin-bottom: 4px;
}

.lobby__settings-group-header {
  display: flex;
  flex-direction: row;
}

.lobby__settings-group-title {
  flex: 1;
}

.lobby__copy-btn {
  border: none;
  text-transform: uppercase;
  background: none;
  cursor: pointer;
  transition: var(--default-transition);
  color: var(--text-color-hint);
}

.lobby__copy-btn:hover {
  color: rgba(0, 0, 0, 1);
}

.lobby__start-game-btn {
  width: 100%;
}

.lobby__game-selection {
  width: 100%;
}

.notification-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  pointer-events: none;
}

.notification {
  min-width: 300px;
  padding: 8px 16px;
  background: #333;
  color: white;
}
.notification:last-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.game-history-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.game-history-entry {
  display: grid;
  grid-template-areas: 'game chevron' 'result chevron';
  grid-template-columns: 1fr 16px;
  grid-column-gap: 8px;
  padding: 0 8px;
  border-radius: var(--border-radius);
  cursor: pointer;
  text-decoration: none;
  color: var(--text-color-default);
}

.game-history-entry:hover {
  background: var(--button-default);
}

.game-history-entry__game {
  grid-area: game;
}

.game-history-entry__result {
  grid-area: result;
}

.game-history-entry__chevron {
  grid-area: chevron;
  align-self: center;
  color: var(--icon-default);
}

.stadt-land-fluss-settings__column {
  background: var(--button-default);
  border-radius: var(--border-radius);
  padding: 0 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  font-size: 0.9rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.stadt-land-fluss-settings__column button {
  display: inline-flex;
  margin-left: 4px;
}

.stadt-land-fluss-settings__add-column {
  display: flex;
  flex-direction: row;
}

.stadt-land-fluss-settings__add-column input {
  flex: 1;
  margin-right: 8px;
}

.taboo-settings__timer {
  position: relative;
}

.taboo-settings__timer input {
  width: 100%;
}

.taboo-settings__timer span {
  position: absolute;
  right: 8px;
  height: 30px;
  top: calc(50% - 15px);
  color: var(--text-color-hint);
  transition: 0.1s ease-in-out all;
  pointer-events: none;
}

/* This is an over-engineered fix for the label overlapping the input[type=number] buttons */

/* Firefox always displays the nudge buttons */
@supports (-moz-transition: all 1s ease) {
  .taboo-settings__timer span {
    right: 32px;
  }
}

/* On iPad these buttons don't exist */
@media screen and (min-device-width: 1024px) {
  .taboo-settings__timer:hover span,
  .taboo-settings__timer input:focus ~ span {
    right: 32px;
  }
}

.stille-post-settings__select {
  width: 100%;
}

.pictionary__select {
  width: 100%;
}

.home {
  padding: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.home__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  width: 100%;
}

.home__join-game {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.home__game-code-input {
  margin-right: 8px;
}

.drawing-area {
  position: relative;
  border: 4px solid rgba(0, 0, 0, 0.1);
}

.player-editor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 64px;
}

.player-editor__card {
  display: flex;
  flex-direction: column;
  width: 340px;
}

.player-editor__actions {
  display: flex;
  flex-direction: row;
}

.player-editor__action-btn {
  flex: 1;
  margin: 4px;
}

.player-editor__color-picker {
  margin-bottom: 8px;
}

.color-picker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.color-picker__color {
  width: 24px;
  height: 24px;
  margin: 2px;
  border: none;
  cursor: pointer;
  border-radius: 100%;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
  transition: var(--default-transition);
}

.color-picker__color:hover,
.color-picker__color--active {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transform: translate(1px, 1px);
}

.color-picker__check {
  padding: 2px;
  opacity: 0.8;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--card-background);
  box-shadow: var(--default-shadow);
}

.header__title {
  margin-left: 16px;
  font-size: 16px;
}

.header__title-link {
  text-decoration: none;
  color: black;
  flex: 1;
  padding: 4px;
}
.header__title-link:hover {
  text-decoration: underline;
}

.header__change-name,
.header__player-name {
  padding: 4px;
  font-size: 14px;
}

.header__player-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header__player-settings {
  display: flex;
  padding: 8px;
  grid-gap: 8px;
  flex-direction: row;
  align-items: center;
  opacity: 1;
  border: none;
  background: none;
  cursor: pointer;
  transition: var(--default-transition);
  color: black;
}

.header__player-settings:hover {
  background: rgba(0, 0, 0, 0.1);
}

.header__player-img {
  width: 48px;
  height: 48px;
}

.game-history {
  margin: 16px auto;
  max-width: 1000px;
}

.game-history__header {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.game-history__back-btn {
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.game-history__team-list {
  display: flex;
  flex-direction: row;
}

.game-history__team-score {
  flex: 1;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  z-index: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
}

.background-selector {
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
}

.background-selector__background {
  border: 2px solid var(--button-default);
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background: none;
  background-size: cover;
  cursor: pointer;
}

.links {
  text-decoration: none;
  color: var(--footer-color);
  padding: 0 8px;
}

/*# sourceMappingURL=index.fd86872a.css.map */
