:root {
  --border-radius: 2px;
  --color-default: #e5e5e5;
  --color-primary: #8bc34a;
  --color-error: #f44336;
  --text-color-default: rgba(0, 0, 0, 0.9);
  --text-color-hint: rgba(0, 0, 0, 0.54);
  --text-color-primary: rgba(255, 255, 255, 0.9);
  --card-background: white;
  --default-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  --page-background: #ddd;
  --control-padding: 8px;
  --font-size: 12px;
  --default-transition: 0.1s ease-in-out all;
  --border-width: 2px;
  --button-default: rgba(0, 0, 0, 0.1);
  --icon-default: rgba(0, 0, 0, 0.54);
  --footer-color: rgba(0, 0, 0, 0.7);
}
